<template>
  <div class="inspect-tasks">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="多点线性核查-任务实施" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="140px" :model="searchData" ref="searchDataRef">
        <!-- 输入框-名称 -->
        <ml-input
          prop="taskNameLike"
          label="名称:"
          placeholder="请输入名称"
          v-model.trim="searchData.taskNameLike"
          :input-style="{ width: '120px' }"
          :style="{ marginBottom: 0 }"
        />
        <!-- 单位 -->
        <ml-select
          prop="companyId"
          placeholder="请选择单位"
          :options="unitOptions"
          label="单位:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 巡检状态 -->
        <ml-select
          prop="inspectOrderStatus"
          placeholder="请选择多点线性核查状态"
          :options="searchStatusData"
          label="多点线性核查状态:"
          labelName="label"
          valueName="value"
          v-model="searchData.inspectOrderStatus"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />

        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ marginBottom: 0, marginRight: '40px' }"
        />
        <el-row style="margin-top: 16px">
          <!-- 搜索按钮 -->
          <ml-button
            :style="{ 'margin-left': '20px' }"
            submitText="搜索"
            submitIcon="el-icon-search"
            cancelText="重置"
            cancelIcon="el-icon-refresh"
            cancelType="danger"
            @click-cancel="resetSearchData"
            @click-submit="searchFn"
          />

          <!-- 添加巡检任务-->
          <ml-button
            v-if="false"
            :style="{ 'margin-bottom': 0 }"
            :showSubmit="false"
            cancelText="添加巡检任务"
            cancelIcon="el-icon-plus"
            cancelType="primary"
            @click-cancel="clickAdd"
          />

          <!-- 按钮 -->
          <ml-button
            :style="{ 'margin-bottom': 0 }"
            submitText="导出"
            submitIcon="el-icon-download"
            @click-submit="errorRecordbleDown"
            :showCancel="false"
          />
        </el-row>
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table
        :data="tabberData.data"
        style="width: 100%"
        @select-all="selectAll"
        @select="select"
        :row-key="row => row.inspectTaskId"
        :height="tabberHeight(319)"
        ref="errorRecordbleRef"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <!-- <el-table-column align="center" type="index" width="80">
            <template #default="scope">
              {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
            </template>
          </el-table-column> -->
        <!-- <el-table-column
            align="center"
            label="单位"
            prop="companyName"
            min-width="15%"
            show-overflow-tooltip
          /> -->
        <el-table-column align="center" label="所属项目" prop="projectName" min-width="15%" />
        <el-table-column align="center" label="多点线性核查计划" prop="planName" min-width="10%" />
        <el-table-column align="center" label="执行人" prop="userName" min-width="15%" />
        <el-table-column align="center" label="电话" prop="telephone" width="110" />
        <el-table-column align="center" label="预计开始时间" prop="startTime" min-width="20%" />
        <el-table-column align="center" label="预计结束时间" prop="endTime" min-width="15%" />
        <el-table-column
          align="center"
          label="多点线性核查实际完成时间"
          prop="completeTime"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="业务类型"
          prop="qualityControlType"
          min-width="15%"
        >
          <template #default="scope">
            <span>{{ scope.row.qualityControlType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="多点线性核查状态"
          prop="inspectOrderStatus"
          min-width="15%"
        >
          <!-- （-1 审核退回 0 待处理 1待审核 2 已办结） -->
          <template #default="scope">
            <span v-if="scope.row.inspectOrderStatus === '-1'">审核退回</span>
            <span v-if="scope.row.inspectOrderStatus === '0'">待处理</span>
            <span v-if="scope.row.inspectOrderStatus === '1'">待审核</span>
            <span v-if="scope.row.inspectOrderStatus === '2'">已办结</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" priop="inspectTaskId" min-width="35%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-view"
              type="success"
              @click="clickDetails(scope.$index, scope.row)"
            >
              查看详情
            </el-button>
            <el-button
              v-if="
                ['0', '-1'].includes(scope.row.inspectOrderStatus) &&
                isAudit(scope.row.inspectUserVos)
              "
              size="mini"
              icon="el-icon-upload2"
              type="primary"
              @click="clickSubmitReport(scope.$index, scope.row)"
            >
              提交报告
            </el-button>
            <el-button
              v-if="scope.row.inspectOrderStatus === '1' && isAmibaManager(scope.row)"
              size="mini"
              icon="el-icon-check"
              type="primary"
              @click="clickCheck(scope.$index, scope.row, true)"
            >
              通过
            </el-button>
            <el-button
              v-if="scope.row.inspectOrderStatus === '1' && isAmibaManager(scope.row)"
              size="mini"
              icon="el-icon-refresh"
              type="danger"
              @click="clickCheck(scope.$index, scope.row)"
            >
              退回
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 办结提示框 -->
    <ml-dialog
      width="600px"
      ref="errorRecordCompleteDialogRef"
      :title="errorRecordCompleteFormData.title"
      @click-submit="errorRecordCompleteDialogSubmit"
      @click-close="errorRecordCompleteDialogClose"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="errorRecordCompleteForm"
          :rules="errorRecordCompleteFormRule"
          ref="errorRecordCompleteFormRef"
        >
          <!-- 办结描述 -->
          <ml-input
            prop="content"
            :placeholder="`请输入${errorRecordCompleteFormData.title}描述`"
            :label="`${errorRecordCompleteFormData.title}描述`"
            type="textarea"
            :rows="5"
            v-model="errorRecordCompleteForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="errorRecordCompleteForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 提交报告提示框 -->
    <ml-dialog
      width="600px"
      ref="submitReportDialogRef"
      title="提交报告"
      @click-submit="submitReportDialogSubmit"
    >
      <template #body>
        <ml-form
          labelWidth="100px"
          style="width: 95%"
          :model="submitReportForm"
          :rules="submitReportFormRule"
          ref="submitReportFormRef"
        >
          <!-- 办结描述 -->
          <ml-date-picker
            prop="startTime"
            placeholder="请选择开始时间"
            label="开始时间:"
            type="datetime"
            :disabled-date="disabledStartDate"
            v-model="submitReportForm.startTime"
          />
          <ml-date-picker
            prop="endTime"
            placeholder="请选择结束时间"
            label="结束时间:"
            type="datetime"
            :disabled-date="disabledEndDate"
            v-model="submitReportForm.endTime"
          />
          <ml-input
            prop="content"
            placeholder="请输入内容描述"
            label="内容描述"
            type="textarea"
            :rows="5"
            v-model="submitReportForm.content"
          />

          <el-form-item>
            <ml-list fileName="fileName" :list="submitReportForm.attachVos" />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSubmitReportSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>

    <!-- 批量导出设备提示框 -->
    <ml-dialog
      ref="errorRecordbleDialogDialogRef"
      :content="errorRecordbleDialogDialogData.content"
      :title="errorRecordbleDialogDialogData.title"
      :showSubmitBtn="selectData.length > 0"
      @click-submit="submitErrorRecordbleDialog"
    />
  </div>
</template>
  
  <script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  formetData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { BASEURL, FILEUPLOADURL } from '@API'

export default {
  name: 'InspectTasks',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const userInfo = computed(() => getters.getUser)
    const toKen = computed(() => getters.getToken)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)

    const isAudit = (arr = []) => {
      const { userId } = userInfo.value
      const userIdArr = arr ? arr.map(item => item.userId) : []
      return userIdArr.includes(userId)
    }
    const isAmibaManager = row => {
      const { userType, companyId } = userInfo.value
      return userType === 'amiba_manager' && companyId === row.companyId
    }

    const disabledStartDate = value => {
      if (submitReportForm.endTime) {
        return value.getTime() > new Date(submitReportForm.endTime).getTime()
      }
      return false
    }

    const disabledEndDate = value => {
      if (submitReportForm.startTime) {
        return (
          value.getTime() < new Date(submitReportForm.startTime).getTime() - 24 * 60 * 60 * 1000
        )
      }
      return false
    }

    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      taskNameLike: getMemoryPage.value.searchData.taskNameLike || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || '',
      inspectOrderStatus: getMemoryPage.value.searchData.inspectOrderStatus || '',
      date: getMemoryPage.value.searchData.date || [],
      planBusinessType: '1',
      qualityControlType: '多点线性核查',
      planTypeLike: getMemoryPage.value.searchData.planTypeLike || ''
    })

    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])

    // 巡检状态 （3 待审核 0 待处理 1已处理 2 已办结）
    const searchStatusData = ref([
      { label: '待处理', value: '0' },
      { label: '待审核', value: '1' },
      { label: '已办结', value: '2' },
      { label: '审核退回', value: '-1' }
    ])
    // 巡检类型
    const inspectionTypeData = ref([
      { label: '临时巡检计划' },
      { label: '日巡检计划' },
      { label: '周巡检计划' },
      { label: '季度巡检计划' },
      { label: '月巡检计划' },
      { label: '年巡检计划' }
    ])
    // 计划业务类型
    const planBusinessData = ref([
      { value: '0', label: '巡检' },
      { value: '1', label: '质控' }
    ])
    // 质检类型
    const qualityTypeData = ref([
      { label: '标样核查' },
      { label: '校正' },
      { label: '多点线性核查' },
      { label: '水样对比' }
    ])

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData, { date: [] })
      tabberData.page === 1 ? getInspectTasksData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getInspectTasksData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/
    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getInspectTasksData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date.length > 0) {
        searchDataParams.startTimeStart = formetData(searchData.date[0])
        searchDataParams.startTimeEnd = formetData(searchData.date[1])
      }
      searchDataParams.taskNameLike = replacePerCent(searchData.taskNameLike)
      searchDataParams.planBusinessType = '1'
      searchDataParams.qualityControlType = '多点线性核查'
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetInspectTasks',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getInspectTasksData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 查看详情
    const clickDetails = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      const { inspectTaskId } = row
      router.push({ path: '/ddTaskDetail', query: { inspectTaskId } })
    }
    // 添加巡检任务
    const clickAdd = () => {
      clearMemoryPageFn(commit, searchData, { date: [] })
      router.push({ path: '/inspectTasksAction' })
    }

    // 通过，退回对话框 el
    const errorRecordCompleteDialogRef = ref()
    // 表单 el
    const errorRecordCompleteFormRef = ref()
    const errorRecordCompleteFormData = reactive({
      title: ''
    })
    const errorRecordCompleteForm = reactive({
      content: '',
      attachVos: []
    })

    // 通过，退回按钮
    const clickCheck = (index, row, isComplete) => {
      errorRecordCompleteFormData.inspectTaskId = row.inspectTaskId
      errorRecordCompleteFormData.title = isComplete ? '通过' : '退回'
      errorRecordCompleteForm.content = ''
      errorRecordCompleteDialogRef.value.showDialog = true
    }
    // 校验
    const errorRecordCompleteFormRule = {
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }
    // 通过，退回确认对证框
    const errorRecordCompleteDialogSubmit = () => {
      const isComplete = errorRecordCompleteFormData.title === '通过'
      const actionName = isComplete ? 'fetchCheckInspectTask' : 'fetchCheckInspectTask'
      const nParams = searchParams(errorRecordCompleteForm)
      nParams.inspectTaskId = errorRecordCompleteFormData.inspectTaskId
      if (isComplete) {
        nParams.status = 'Y'
      } else {
        nParams.status = 'N'
      }
      errorRecordCompleteFormRef.value.CustomFormRef.validate()
        .then(() => {
          dispatch(actionName, nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })

              errorRecordCompleteDialogRef.value.showDialog = false
              tabberData.page === 1 ? getInspectTasksData() : (tabberData.page = 1)
            }
          })
        })
        .catch(() => {})
    }

    // 提交报告
    const submitReportDialogRef = ref()
    const submitReportFormRef = ref()
    const partOptions = ref([])
    const submitReportForm = reactive({
      startTime: '',
      endTime: '',
      content: '',
      attachVos: [],
      inspectTaskId: ''
    })
    // 校验
    const submitReportFormRule = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }

    // 上传附件
    const onSubmitReportSuccess = files => {
      if (files.data) {
        submitReportForm.attachVos = [...submitReportForm.attachVos, ...files.data]
      }
    }
    const clickSubmitReport = (index, row) => {
      submitReportForm.inspectTaskId = row.inspectTaskId
      submitReportDialogRef.value.showDialog = true
    }
    // 提交报告
    const submitReportDialogSubmit = () => {
      submitReportFormRef.value.CustomFormRef.validate()
        .then(() => {
          const nParams = searchParams(submitReportForm, ['startTime', 'endTime'], true)
          const startTime = submitReportForm.startTime
          const endTime = submitReportForm.endTime
          if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
            commit('setError', {
              status: true,
              title: '',
              message: '开始时间不能大于结束时间',
              type: 'warning'
            })
            return
          }
          if (submitReportForm.startTime) {
            nParams.startTime = formetData(submitReportForm.startTime)
          }
          if (submitReportForm.endTime) {
            nParams.endTime = formetData(submitReportForm.endTime)
          }
          dispatch('fetchAddInspectRecord', nParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              submitReportDialogRef.value.showDialog = false
              tabberData.page === 1 ? getInspectTasksData() : (tabberData.page = 1)
            }
          })
        })
        .catch(() => {})
    }

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    const selectUnit = () => {
      searchData.projectId = ''
      searchFn()
    }

    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        }
      }
    )

    // 取消对证框
    const errorRecordCompleteDialogClose = () => {
      errorRecordCompleteForm.attachVos = []
    }

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        errorRecordCompleteForm.attachVos = [...errorRecordCompleteForm.attachVos, ...files.data]
      }
    }

    // 导出
    // 选中的添加到列表
    const errorRecordbleRef = ref()
    // const getSelection = selection => selection.map(item => item)
    // 选择指派人列表
    const selectData = ref([])
    // 选中单个
    const select = selection => {
      selectData.value = selection
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = selection
    }
    const errorRecordbleDialogDialogRef = ref()
    const errorRecordbleDialogDialogData = reactive({
      title: '批量导出',
      content: ''
    })
    // 弹出层
    const errorRecordbleDown = () => {
      if (selectData.value.length <= 0) {
        errorRecordbleDialogDialogData.content = '请选择巡检任务'
      } else {
        errorRecordbleDialogDialogData.content = '确定导出选中的巡检任务吗？'
      }
      errorRecordbleDialogDialogRef.value.showDialog = true
    }
    const jsonFields = reactive({
      所属项目: 'projectName',
      巡检计划: 'planName',
      执行人: 'userName',
      电话: 'telephone',
      预计开始时间: 'startTime',
      预计结束时间: 'endTime',
      巡检实际完成时间: 'completeTime',
      巡检状态: 'inspectOrderStatus',
      巡检内容: 'cntent',
      巡检报告: 'taskContent'
    })

    // 确定
    const { export_json_to_excel } = require('../../../utils/excel/Export2Excel')
    const formatJson = (filterVal, jsonData) =>
      jsonData.map(v =>
        filterVal.map(j => {
          let nVal = v[j]
          if (j === 'inspectOrderStatus') {
            switch (v[j]) {
              case '-1':
                nVal = '审核退回'
                break
              case '0':
                nVal = '待处理'
                break
              case '1':
                nVal = '待审核'
                break
              case '2':
                nVal = '已办结'
                break
              default:
                nVal = '待处理'
                break
            }
          }
          return nVal
        })
      )
    const submitErrorRecordbleDialog = () => {
      //导出的方法
      require.ensure([], () => {
        const tHeader = []
        // 上面设置Excel的表格第一行的标题
        const filterVal = []

        Object.keys(jsonFields).map(item => {
          tHeader.push(item)
          filterVal.push(jsonFields[item])
        })
        // 上面的index、nickName、name是tableData里对象的属性
        const list = selectData.value //把data里的tableData存到list
        const data = formatJson(filterVal, list)
        // console.log(data)
        // console.log(filterVal)
        // console.log(list)
        export_json_to_excel(tHeader, data, '列表excel')
        selectData.value = []
        errorRecordbleRef.value.clearSelection()
        errorRecordbleDialogDialogRef.value.showDialog = false
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getInspectTasksData()
    })
    return {
      jsonFields,
      errorRecordbleRef,
      selectData,
      select,
      selectAll,
      errorRecordbleDialogDialogRef,
      errorRecordbleDialogDialogData,
      errorRecordbleDown,
      submitErrorRecordbleDialog,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickDetails,
      clickAdd,
      searchStatusData,
      formetData,
      userInfo,
      clickCheck,
      errorRecordCompleteDialogRef,
      errorRecordCompleteFormRef,
      errorRecordCompleteFormData,
      errorRecordCompleteForm,
      errorRecordCompleteFormRule,
      errorRecordCompleteDialogSubmit,
      errorRecordCompleteDialogClose,
      action,
      onSuccess,
      toKen,
      clickSubmitReport,
      submitReportDialogRef,
      submitReportFormRef,
      submitReportForm,
      submitReportDialogSubmit,
      submitReportFormRule,
      partOptions,
      onSubmitReportSuccess,
      isAudit,
      isAmibaManager,
      unitOptions,
      projectData,
      selectUnit,
      disabledStartDate,
      disabledEndDate,
      planBusinessData,
      qualityTypeData,
      inspectionTypeData
    }
  }
}
</script>
  
  <style lang="scss">
.inspect-tasks {
  @extend %params-global;
  .el-range-input,
  .el-select .el-input {
    .el-input__inner {
      width: 130px;
    }
  }
  .el-range-editor .el-range-input {
    width: 140px;
  }
}
.attachVosView-dialog {
  .el-dialog__body {
    justify-content: start;
  }
  .el-select .el-input {
    .el-input__inner {
      width: 120px !important;
    }
  }
}
</style>
  